<template>
  <div class="app-container">
    <div class="searchForm_box">
      <el-form :inline="true" :model="searchForm" size="small">
        <el-form-item>
          <el-checkbox v-model="searchForm.person">只看自己</el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="請輸入內容"
            v-model="searchForm.searchTxt"
            class="input-with-select"
          >
            <el-select
              v-model="searchForm.searchChoose"
              slot="prepend"
              placeholder="請選擇"
              style="width: 130px"
            >
              <el-option label="店鋪名稱" value="shop_name"></el-option>
              <el-option label="員工姓名" value="realname"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="狀態">
          <el-select v-model="searchForm.status" clearable placeholder="請選擇">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="值守時間">
          <el-date-picker
            v-model="dateSearchRange"
            style="width: 240px"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="daterange"
            range-separator="-"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >搜索</el-button
          >
          <el-button icon="el-icon-delete" size="mini" @click="multDelete"
            >批量刪除</el-button
          >
          <el-button icon="el-icon-add" size="mini" @click="add"
            >添加</el-button
          >
          <span style="padding-left: 120px"
            >業績：<font style="color: red">{{ money }}元</font></span
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table
      ref="multipleTable"
      @selection-change="handleSelectionChange"
      row-key="Id"
      :data="taskListData"
      border
      class="table"
      v-loading="loading"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="ID" prop="id"></el-table-column>
      <el-table-column label="客服" prop="realname"></el-table-column>
      <el-table-column label="店鋪" prop="shop_name"></el-table-column>
      <el-table-column label="值守業績" prop="money"></el-table-column>
      <el-table-column label="值守開始" prop="start_time" width="160">
        <template slot-scope="scope">
          {{ formatDate(scope.row.start_time) }}
        </template>
      </el-table-column>
      <el-table-column label="值守結束" prop="end_time" width="160">
        <template slot-scope="scope">
          {{ formatDate(scope.row.end_time) }}
        </template>
      </el-table-column>
      <el-table-column label="上報人" prop="create_by" align="center">
      </el-table-column>
      <el-table-column label="狀態" prop="status">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1" style="color: #1890ff"
            >未處理</span
          >
          <span v-if="scope.row.status == 2" style="color: darkgreen"
            >已處理</span
          >
          <span v-if="scope.row.status == 3" style="color: red">異常</span>
        </template>
      </el-table-column>
      <el-table-column label="上傳時間" prop="create_time" width="160">
        <template slot-scope="scope">
          {{ scope.row.create_time | setDate }}
        </template>
      </el-table-column>
      <el-table-column label="備註" prop="msg" width="250"></el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="freshRecord(scope.row)"
            >重新計算</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <!-- 添加或修改部門對話框 -->
    <el-dialog
      title="添加值守時段(計算業績)"
      :visible.sync="open"
      width="600px"
      append-to-body
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="所屬店鋪" prop="shop_id">
              <el-select v-model="form.shop_id" filterable remote>
                <el-option
                  v-for="item in shopData"
                  :key="item.shop_id"
                  :label="item.shop_name"
                  :value="item.shop_id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="值守時間">
              <el-date-picker
                v-model="dateRange"
                type="datetimerange"
                align="right"
                start-placeholder="開始日期"
                end-placeholder="結束日期"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['8:00:00', '18:00:00']"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="備註" prop="remark">
              <el-input
                v-model="form.remark"
                placeholder="若有備註可填寫，無則為空"
                maxlength="50"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">確 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listMoney,
  addRecord,
  shopList,
  deleteRecord,
  freshRecord,
} from "@/api/staffmoney";
import { formatDate } from "../../utils/tools";
export default {
  name: "list",
  data() {
    return {
      money: 0,
      open: false,
      shopData: [],
      form: {},
      dateRange: [],
      rules: {
        shop_id: [
          { required: true, message: "請選擇客服值守的店鋪", trigger: "blur" },
        ],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一個月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三個月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      dateSearchRange: [],
      //
      options: [
        {
          value: "1",
          label: "未處理",
        },
        {
          value: "2",
          label: "已處理",
        },
      ],

      loading: true,
      searchForm: {
        searchTxt: "",
        searchChoose: "shop_name",
        shop_name: "",
        realname: "",
        person: true,
      },
      page: 1,
      pageSize: 50,
      total: 0,
      record_total: 0,
      taskListData: [],
      multipleSelection: [],
    };
  },
  methods: {
    timeDefault() {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      return [formatDate(start, "Y-m-d"), formatDate(end, "Y-m-d")];
    },
    add() {
      this.open = true;
      shopList({}).then((response) => {
        this.shopData = response.data;
      });
    },
    submitForm() {
      if (this.dateRange.length < 2) {
        this.$modal.alertWarning("請選擇客服值守時間段");
        return false;
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let params = this.form;
          params["start_time"] = this.dateRange[0];
          params["end_time"] = this.dateRange[1];
          addRecord(params)
            .then((response) => {
              this.$modal.msgSuccess("新增成功");
              this.open = false;
              this.getMoneyList();
            })
            .catch(() => {});
        }
      });
    },
    cancel() {
      this.open = false;
      this.form = {};
      this.dateRange = [];
    },
    getMoneyList() {
      let params = {
        page: this.page,
        pageSize: this.pageSize,
        status: this.searchForm.status,
        person: this.searchForm.person,
        shop_name:
          this.searchForm.searchChoose == "shop_name"
            ? this.searchForm.searchTxt
            : "",
        realname:
          this.searchForm.searchChoose == "realname"
            ? this.searchForm.searchTxt
            : "",
      };
      if (this.dateSearchRange.length == 2) {
        params["start_time"] = this.dateSearchRange[0];
        params["end_time"] = this.dateSearchRange[1];
      }
      this.loading = true;
      listMoney(params).then((response) => {
        this.taskListData = response.data.list;
        this.total = response.data.count;
        this.money = response.data.money;
        this.loading = false;
      });
    },
    // 分頁
    handleSizeChange(val) {
      this.pageSize = val;
      this.getMoneyList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getMoneyList();
    },
    handleQuery() {
      this.page = 1;
      this.getMoneyList();
    },

    freshRecord(row) {
      let val = "";
      let params = {
        id: row.id,
        status: 1,
      };
      this.$modal
        .confirm("確定要<span style='color:red'>重新計算業績</span>？")
        .then(function () {
          freshRecord(params);
        })
        .then(() => {
          this.getMoneyList();
          this.$modal.msgSuccess("操作成功");
        })
        .catch(() => {});
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    multDelete() {
      let ids = "";
      let idsList = [];
      if (this.multipleSelection.length <= 0) {
        this.$modal.msgWarning("請選擇要刪除的數據");
        return false;
      }
      for (var i in this.multipleSelection) {
        idsList.push(this.multipleSelection[i].id);
      }

      this.$modal
        .confirm("只能刪除本人添加的數據，確定要刪除？")
        .then(function () {
          return deleteRecord({ ids: idsList.toString() });
        })
        .then(() => {
          this.$modal.msgSuccess("操作成功");
          this.getMoneyList();
        })
        .catch(() => {});
    },
  },
  created() {
    this.dateSearchRange = this.timeDefault();
    this.getMoneyList();
  },
};
</script>
<style lang="scss">
.hiddenCheck {
  /*visibility: hidden;*/
}

.agentManage {
  padding: 10px;

  > .searchForm_box {
    padding: 10px;
    background: #fff;
    margin: 10px 0;

    .el-form {
      .el-form-item {
        margin-bottom: 10px;
      }
    }
  }
}

.clickDetialBtn {
  color: rgb(24, 144, 255);
  cursor: pointer;
}

.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.imgView-dialog {
  width: 800px;
  height: 800px;
  align-content: center;
  text-align: center;
}
</style>
