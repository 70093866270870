import http from "../utils/request"


//客服绩效列表
export function listMoney(data) {
    return http({
        url: '/admin/staff/list',
        method: 'post',
        data: data
    })
}

//添加时段
export function addRecord(data) {
    return http({
        url: '/admin/staff/add',
        method: 'post',
        data: data
    })
}

//删除时段
export function deleteRecord(data) {
    return http({
        url: '/admin/staff/delete',
        method: 'post',
        data: data
    })
}

//重置绩效（刷新绩效）
export function freshRecord(data) {
    return http({
        url: '/admin/staff/reset',
        method: 'post',
        data: data
    })
}

// 店铺商品导出
export function shopList(data) {
    return http({
        url: '/admin/staff/shop',
        method: 'post',
        data: data,
    })
}

